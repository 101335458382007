<template >
  <v-container class="my-10">
    <Divisor texto="Aplicativo"/>
    <v-row justify="start" dense class="ma-0 pa-0 mx-auto">
      <v-col v-for="(config, index) in configs" :key="index" cols="3" class="pa-1 my-auto mx-auto"
        @click="go(config.params)">
        <v-hover v-slot="{ hover }" close-delay="98" open-delay="155">
          <v-card class="ma-0 pa-0" style="cursor:pointer" :focus="hover" :elevation="hover ? 16 : 2" :loading="loading"
            :color="hover ? '#67b4c2' : '#5ea4b0'">
            <v-responsive :aspect-ratio="16 / 8">
              <v-card-title class="white--text font-weight-bold ">
                <v-row dense>
                  <v-col cols="9">
                    <p class="text-wrap text-truncate h3 ma-0">{{ config.text }}</p>
                    <p class="text-wrap text-truncate caption ma-0">{{ config.label }}</p>    
                  </v-col>
                  <v-col cols="3">
                    <v-icon dark size="50">{{ config.icon }}</v-icon>
                  </v-col>
                </v-row>
              </v-card-title>
            </v-responsive>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    
    <!-- LINKS UTEIS -->
    <v-row class="my-10">
      <LinksUteis/>
    </v-row>
  </v-container>
</template>
<script>
import api from '@/http'

export default {
    name: "Login",
    components:{
      LinksUteis:()=>import('./LinksUteis.vue'),
      Divisor:()=>import('./Divisor.vue'),
    },
    data: () => ({
        loading: false,
        configs: [],
    }),
    methods: {
        go(val) {
            if (this.$router.currentRoute.name !== val) {
                this.$router.push({ name: val }).catch(() => { });
            }
        },
        async get_apps() {
            this.loading = true;
            api("base/apps/?local=TODOS")
                .then(response => {
                this.configs = response.data;
            }).finally(() => {
                this.loading = false;
            });
        }
    },
    mounted() {
        this.get_apps();
    },
}
</script>
